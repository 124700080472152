<template>
  <div>
    <b-card
      no-body
      class="mb-2 pb-2 pl-2 pr-2"
    >
      <h4 class="mt-4">
        Editar arquivo
      </h4>
      <validation-observer
        ref="editHistory"
        #default="{invalid}"
      >
        <b-form
          class="auth-login-form mt-2"
          @submit.prevent
        >
          <b-row>
            <b-col
              md="6"
            >
              <!-- title -->
              <b-form-group
                label-for="name"
                label="Título"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Título"
                  rules="required"
                >
                  <b-input-group
                    :append="count"
                  >
                    <b-form-input
                      id="title"
                      v-model="title"
                      name="register-number"
                      :state="errors.length > 0 ? false:null"
                      maxlength="47"
                      @keyup="countCaracter"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
            >

              <!-- file -->
              <b-form-group
                label-for="file"
                label="Escolher arquivo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Escolher arquivo"
                  rules="size:10000|mimes:image/jpeg,image/png,application/pdf"
                >
                  <b-form-file
                    v-model="file"
                    :placeholder="placeholderFile"
                    :state="errors.length > 0 ? false:null"
                    accept=".jpg, .pdf, .png"
                    browse-text="Escolher"
                  />
                  <br>
                  <template v-if="errors[0]">
                    <small class="text-danger">{{ errors[0] }}</small>
                    <br>
                  </template>
                  <small>Utilize arquivos em PDF, JPEG e PNG, com o tamanho máximo de 10 MB.</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>

          <b-row>
            <b-col cols />
            <b-col
              cols="3"
              class="mt-2 text-right"
            >
              <b-button
                type="button"
                variant="outline-primary"
                @click="cancel"
              >
                Cancelar
              </b-button>

              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                class="ml-2"
                :disabled="invalid"
                @click="validationForm"
              >
                Salvar
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  ValidationProvider, ValidationObserver, localize, extend,
} from 'vee-validate'
import { size, mimes } from '@validations'
import {
  BCard, BForm, BFormGroup, BFormInput, BInputGroup, BButton, BCol, BRow, BFormFile,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    BRow,
    BCol,
    BFormFile,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      id: '',
      title: '',
      file: [],
      placeholderFile: '1 arquivo selecionado',
      count: '0 / 47',
    }
  },

  mounted() {
    localize(this.$store.getters['cuido/locale'])

    extend('size', {
      ...size,
      message: 'Tamanho inválido. Insira um arquivo com até 10MB.',
    })
    extend('mimes', {
      ...mimes,
      message: 'Formato inválido. Insira um arquivo PDF, JPEG ou PNG.',
    })

    const image = this.$store.getters['library/title']
    this.id = image.id
    this.title = image.title
    this.countCaracter()
  },

  methods: {
    countCaracter() {
      this.count = `${this.title.length} / 47`
    },

    validationForm() {
      this.$refs.editHistory.validate().then(success => {
        if (success) {
          const data = { id: this.id, title: this.title, file: this.file }
          this.$store.dispatch('library/editFile', { data })
            .then(response => {
              if (response.error !== null) {
                this.$router.push('/library')
              }
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ops... Algo deu errado, tente novamente',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    cancel() {
      this.$router.push('library')
    },
  },
}
</script>
